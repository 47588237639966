import { useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

function App() {
  const baseUrl =
    "http://inailcheckin-server.tokyonailsandspa.ca/api/employees/";

  const [availableEmployees, setAvailableEmployees] = useState([]);
  const [ocupiedEmployees, setOcupiedEmployees] = useState([]);

  //create a func to fetch available employees
  const fetchAvailableEmployees = async () => {
    try {
      const response = await axios.get(baseUrl + "available_employees_turn");
      if (response) {
        setAvailableEmployees(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  //create a func to fetch ocupied one
  const fetchOcupiedEmployees = async () => {
    try {
      const response = await axios.get(baseUrl + "ocupied_employees_turn");
      if (response) {
        setOcupiedEmployees(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //create a func to update employee status
  const setEmployeeStatus = async (id, status) => {
    //console.log('Employee id: ' + id + ' and code: ' + status)

    try {
      const formData = {
        employee_id: id,
        employee_status: status,
      };

      await axios
        .post(baseUrl + "set_employee_turn_status", formData)
        .then((response) => {
          //send request to reload data
          if (response.data.code === 200) {
            console.log("success");
          } else {
            alert("fail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  //put those array in useEffect to track
  useEffect(() => {
    fetchAvailableEmployees();
  }, [availableEmployees]);

  useEffect(() => {
    fetchOcupiedEmployees();
  }, [ocupiedEmployees]);

  return (
    <div className="container my-3">
      <div className="card">
        <div className="card-header">
          <h1>iNail Staff Turn</h1>
        </div>
        <div className="card-body">
          <div className="container-fluid">
            <div className="staff_turn_wrapper">
              <div className="staff_turn_block">
                <h2>Available</h2>
                {availableEmployees.length > 0 ? (
                  <ul className="staff_turn_list_wrapper">
                    {availableEmployees.map((employee, index) => (
                      <li className="staff_turn_list_item" key={index}>
                        <button
                          onClick={() => setEmployeeStatus(employee.id, 0)}
                          data-id={employee.id}
                          data-code={0}
                        >
                          {employee.staff_name}
                        </button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="alert alert-warning" role="alert">
                    No staff is available
                  </div>
                )}
                <small>Click to remove staff</small>
              </div>
              <div className="staff_turn_block">
                <h2>Staff List</h2>
                {ocupiedEmployees.length > 0 ? (
                  <ul className="staff_turn_list_wrapper">
                    {ocupiedEmployees.map((employee, index) => (
                      <li className="staff_turn_list_item" key={index}>
                        <button
                          onClick={() => setEmployeeStatus(employee.id, 1)}
                          data-id={employee.id}
                          data-code={1}
                        >
                          {employee.staff_name}
                        </button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="alert alert-warning" role="alert">
                    No staff is ocupied
                  </div>
                )}
                <small>Click to add staff to available status</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="text-center py-3">
        <p className="text-muted">
          Version 1.0 - By <a href="https://lilcasoft.ca">Lilcasoft.ca</a>
        </p>
      </footer>
    </div>
  );
}

export default App;
